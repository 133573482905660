<div class="container-fluid">
  <!-- Hero Section -->
  <div class="row bg-black text-white py-5">
    <div class="col-md-12 text-center">
      <img src="assets/npc-logo.jpeg" alt="NPC Logo" class="img-fluid mb-3" style="max-height: 100px;">
      <h1>NPC Corporate Health</h1>
      <p class="lead">Keeping Your Office Pest-Free with Comprehensive Solutions</p>
    </div>
  </div>

  <!-- Professional Pest Management Section -->
<!-- NPC Advantage Section -->
<!-- NPC Advantage Section -->
<div class="npc-advantage-section bg-light">
  <div class="container">
    <h2 class="npc-advantage-title">Why npc?</h2>
    <p class="npc-advantage-lead">
      NPC boasts of a team of specialists who have the required expertise & a proven track record in the management of pests. With an in-depth understanding of the extent of damage pests can cause, NPC has developed in-house, an integrated program to manage pests with the following qualities.
    </p>

    <div class="row">
      <!-- Tailored Solutions -->
      <div class="col-md-6 mb-4">
        <div class="npc-advantage-card">
          <div class="npc-advantage-card-body">
            <i class="fas fa-cogs fa-3x npc-advantage-icon"></i>
            <h4 class="npc-advantage-card-title">TAILORED SOLUTIONS</h4>
            <p class="npc-advantage-card-text">
              Customized solutions based on the size and operations of the company.
            </p>
          </div>
        </div>
      </div>

      <!-- Methodical Treatment -->
      <div class="col-md-6 mb-4">
        <div class="npc-advantage-card">
          <div class="npc-advantage-card-body">
            <i class="fas fa-search fa-3x npc-advantage-icon"></i>
            <h4 class="npc-advantage-card-title">METHODICAL TREATMENT</h4>
            <p class="npc-advantage-card-text">
              Detecting problems at the source with thorough inspections, application, monitoring & recommendations on sanitation & proofing.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <!-- Anti-Mosquito Treatment Section -->
  <!-- <div class="">
    <div class="container">
      <h2 class="title">Anti-Mosquito Treatment</h2>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-body text-center">
              <i class="fas fa-mosquito fa-4x mb-3 text-black"></i>
              <p>
                We achieve sustainable mosquito control through larvicidal treatment, adulticidal treatment, foliar spray, and fogging. Our methods ensure protection from vector-borne diseases.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->

<!-- Left-Right Alternating Content Section -->
<!-- Left-Right Alternating Content Section -->
<div class="section">
  <div class="container">
    <h2 class="section-title">Our Comprehensive Solutions</h2>

    <!-- Left-Aligned Content -->
    <div class="row align-items-center mb-5 animated left">
      <div class="col-md-6">
        <img src="assets/home1.webp" alt="Cockroach Management" class="img-fluid rounded">
      </div>
      <div class="col-md-6">
        <h3 class="mb-3">Cockroach Management</h3>
        <p>
          Our GoldSeal Cockroach Management includes periodic audits, baiting and trapping, residual spray, monitoring, and proofing to ensure long-term solutions. We use CIB- and RC-approved chemicals that are safe for humans and pets.
        </p>
        <ul>
          <li><strong>Periodic Audits:</strong> Regular inspections to prevent re-infestation.</li>
          <li><strong>Baiting and Trapping:</strong> Strategic techniques to exterminate cockroaches.</li>
          <li><strong>Residual Spray:</strong> Safe chemicals for inaccessible areas.</li>
        </ul>
      </div>
    </div>

    <!-- Right-Aligned Content -->
    <div class="row align-items-center mb-5 animated right">
      <div class="col-md-6 order-md-2">
        <img src="assets/home5.webp" alt="Rodent Management" class="img-fluid rounded">
      </div>
      <div class="col-md-6 order-md-1">
        <h3 class="mb-3">Rodent Management</h3>
        <p>
          Our Pied Piper® system uses Roban, Trubble Gum, and Rodabox for effective rodent control. It adheres to international food safety standards and ensures a pest-free environment.
        </p>
        <ul>
          <li><strong>Roban:</strong> Harmless rodent poison for outdoor use.</li>
          <li><strong>Trubble Gum:</strong> Glue-based traps for indoor use.</li>
          <li><strong>Rodabox:</strong> Tamper-proof units for safe rodent control.</li>
        </ul>
      </div>
    </div>

    <!-- Left-Aligned Content -->
    <div class="row align-items-center mb-5 animated left">
      <div class="col-md-6">
        <img src="assets/mosquito-treatment.jpg" alt="Anti-Mosquito Treatment" class="img-fluid rounded">
      </div>
      <div class="col-md-6">
        <h3 class="mb-3">Anti-Mosquito Treatment</h3>
        <p>
          We achieve sustainable mosquito control through larvicidal treatment, adulticidal treatment, foliar spray, and fogging. Our methods ensure protection from vector-borne diseases.
        </p>
        <ul>
          <li><strong>Fogging Control:</strong> Effective fogging to eliminate adult mosquitoes.</li>
          <li><strong>Larva Control:</strong> Targeting mosquito breeding grounds.</li>
          <li><strong>ULV Fogging:</strong> Ultra-low volume fogging for large areas.</li>
        </ul>
      </div>
    </div>

    <!-- Right-Aligned Content -->
    <div class="row align-items-center mb-5 animated right">
      <div class="col-md-6 order-md-2">
        <img src="assets/audit-monitoring.jpg" alt="Pest Audit" class="img-fluid rounded">
      </div>
      <div class="col-md-6 order-md-1">
        <h3 class="mb-3">Pest Audit and Monitoring</h3>
        <p>
          We conduct thorough pest audits to identify infestation levels, breeding zones, and entry points. Our monitoring system ensures long-term pest control and prevention.
        </p>
        <ul>
          <li><strong>Initial Inspection:</strong> Detailed assessment of the premises.</li>
          <li><strong>Trend Analysis:</strong> Regular monitoring to evaluate pest activity.</li>
          <li><strong>Proofing:</strong> Structural improvements to prevent future infestations.</li>
        </ul>
      </div>
    </div>
  </div>
</div>
  <!-- FAQ Section -->
  <div class="section visible bg-light">
    <div class="container">
      <h2 class="">Frequently Asked Questions</h2>
      <div class="accordion" id="faqAccordion">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button"  type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              What pests do you handle?
            </button>
          </h2>
          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#faqAccordion">
            <div class="accordion-body">
              We handle a wide range of pests, including cockroaches, rodents, mosquitoes, termites, and more.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Are your chemicals safe?
            </button>
          </h2>
          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#faqAccordion">
            <div class="accordion-body">
              Yes, we use CIB- and RC-approved chemicals that are safe for humans and pets.
            </div>
          </div>
        </div>
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingThree">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Do you offer emergency services?
            </button>
          </h2>
          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#faqAccordion">
            <div class="accordion-body">
              Yes, we offer 24/7 emergency pest control services.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <div class="row bg-dark text-white py-4">
      <div class="col-md-12 text-center">
          <p>
              Reg. Office: 
              <a 
                href="https://www.google.com/maps?q=No.+15%2FB,+Kolathur,+Murugan+Koil+Street,+Chennai+-+600099,+Tamilnadu,+India" 
                target="_blank" 
                class="text-white"
              >
                No. 15/B, Kolathur, Murugan Koil Street, Chennai - 600099, Tamilnadu, India
              </a>
            </p>          <p>
          Email: <a href="mailto:info&#64;npcpest.net" class="text-white">info&#64;npcpest.net</a> |
          Phone: <a href="tel:+918637454428" class="text-white">+91 8637454428</a> 
        </p>
      </div>
    </div>

<!-- Chatbot Toggle -->
<!-- <div class="chatbot-toggle" (click)="toggleChatbot()">
  <i class="fas fa-comments fa-2x"></i>
</div> -->

<!-- Chatbot -->
<!-- <div class="chatbot" [class.open]="isChatbotOpen">
  <div class="chatbot-header" (click)="toggleChatbot()">
    <h4>NPC Chatbot</h4>
  </div>
  <div class="chatbot-body">
    <div *ngFor="let message of messages" class="message">
      {{ message }}
    </div>
  </div>
  <div class="chatbot-footer">
    <input [(ngModel)]="userInput" placeholder="Type a message..." (keyup.enter)="sendMessage()">
    <button (click)="sendMessage()"><i class="fas fa-paper-plane"></i></button>
  </div>
</div> -->