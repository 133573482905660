import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { LoginComponent } from './@shared/login/login.component';

export const routes: Routes = [
    // {
    //     path:'login',
    //     loadChildren:() =>import ('./@shared/login/login.module').then(u=>u.LoginModule)
    // },
    // {
    //     path:'',
    //     loadChildren:() =>import ('./pages/pages.module').then(u=>u.PagesModule)
    // }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
  })
  export class AppRoutingModule {}
