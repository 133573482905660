import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimengModule } from './modules/primeng.module';
import { AlertService } from './@core/services/alert.service';
import { MessageService } from 'primeng/api';
import { LoadingService } from './@core/services/spinner-service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet,PrimengModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  providers:[AlertService,MessageService]
})
export class AppComponent {
  title = 'galaxy-ion';
  constructor(public loadingService: LoadingService) {}
}
