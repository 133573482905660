import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
// import { PrimengModule } from './modules/primeng.module';

@Component({
  selector: 'app-root',
  standalone:true,
  imports:[],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  isChatbotOpen = false; // Tracks chatbot visibility
  messages: string[] = []; // Stores chat messages
  userInput: string = ''; // Stores user input for chatbot

  constructor() {}

  // Initialize Intersection Observer for section animations
  ngAfterViewInit() {
    this.setupGridAnimations();
  }

  setupGridAnimations() {
    const sections = document.querySelectorAll('.row.align-items-center.animated');
  
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            console.log('Element in view:', entry.target);
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.1, // Trigger when 10% of the element is visible
      }
    );
  
    sections.forEach((section) => {
      observer.observe(section);
    });
  }

  // Toggle chatbot visibility
  toggleChatbot() {
    this.isChatbotOpen = !this.isChatbotOpen;
  }

  // Send a message in the chatbot
  sendMessage() {
    if (this.userInput.trim()) {
      this.messages.push(`You: ${this.userInput}`);
      const response = this.getResponse(this.userInput);
      this.messages.push(`Bot: ${response}`);
      this.userInput = '';
    }
  }

  // Hardcoded chatbot responses
  getResponse(userInput: string): string {
    if (userInput.toLowerCase().includes('pest')) {
      return 'We offer comprehensive pest management solutions.';
    } else if (userInput.toLowerCase().includes('rodent')) {
      return 'Our Pied Piper® system is designed for effective rodent control.';
    } else if (userInput.toLowerCase().includes('mosquito')) {
      return 'We provide anti-mosquito treatments to ensure a safe environment.';
    } else {
      return 'How can I assist you further?';
    }
  }
}
