import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/spinner-service';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,  // Handles login and token storage
    private loadingService: LoadingService // Handles loading spinner
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Show loading spinner when request starts
    this.loadingService.show();

    // Get the token from the auth service
    const authToken = this.authService.getAuthToken();

    // Clone the request and add the Authorization header if a token exists
    const authReq = authToken ? this.addTokenToRequest(req, authToken) : req;

    // Pass the cloned request to the next handler in the chain
    return next.handle(authReq).pipe(
      finalize(() => {
        // Hide the loading spinner when the request completes
        this.loadingService.hide();
      })
    );
  }

  private addTokenToRequest(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }
}